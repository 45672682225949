// App.js
import React, { useState } from 'react';
import './App.css';

// Sequence Page Component.
const SequencePage = ({ text, options, onNext, subText, className }) => (
  <div className={`sequence-page ${className}`}>
    <div className="text-2xl fade-in">
      {text.split('\n').map((line, i) => (
        <p key={i} style={{ margin: 0 }}>{line}</p>
      ))}
      {subText && (
        <p className="homunati-text" style={{ color: '#EF9D5F', margin: 0 }}>{subText}</p>
      )}
    </div>
    <div className="mt-4">
      {options.map((option, index) => (
        <span
          key={index}
          onClick={onNext}
          className={`sequence-link ${option === 'click to proceed' ? 'fade-in' : ''}`}
        >
          {option}
        </span>
      ))}
    </div>
  </div>
);

// Image Overlay Component
const ImageOverlay = ({ isVisible, imageSrc, onClose }) => {
  if (!isVisible) return null;

  const handleClick = (e) => {
    if (e.target.className.includes('overlay-page')) {
      onClose();
    }
  };

  return (
    <div className="overlay-page active" onClick={handleClick}>
      <img
        src={imageSrc}
        alt="overlay content"
        className="overlay-image fade-in"
      />
    </div>
  );
};

function App() {
  const [currentPage, setCurrentPage] = useState(0);
  const [showMoonOverlay, setShowMoonOverlay] = useState(false);
  const [showWisdomOverlay, setShowWisdomOverlay] = useState(false);
  const [showHandOverlay, setShowHandOverlay] = useState(false);

  const sequences = [
    {
      text: "say Homu [ha-moo] as you pray",
      options: ["click to proceed"]
    },
    {
      text: "did you do it?",
      options: ["Yes"]
    },
    {
      text: "you sure?",
      options: ["Yes"]
    },
    {
      text: "good, cuz i can't tell if you did.\ni'm just Homu's dev lol.\nbut he's always watching.\nwelcome to\n\n",
      subText: "The Homunati",
      options: ["click to enter"]
    }
  ];

  const showNext = () => {
    if (currentPage < sequences.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderContent = () => {
    if (currentPage < sequences.length) {
      const isSpecialPage = currentPage === 3; // Assuming the special page is the fourth one

      return (
        <SequencePage
          text={sequences[currentPage].text}
          options={sequences[currentPage].options}
          subText={sequences[currentPage].subText}
          onNext={showNext}
          className={isSpecialPage ? 'sequence-page-smaller' : ''}
        />
      );
    }

    // Homu welcome page
    return (
      <div className="page active">
        <div className="window-controls">
          <a 
            href="https://dexscreener.com/solana/cysjrwtrdkjlbzx4frjuh9e95tht4vsjd4fkw5qgye4o" 
            className="control-button"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img src="/dex.png" alt="DEX" className="control-icon" />
          </a>
          {/* <a href="#" className="control-button">
            <img src="/telegram.png" alt="Telegram" className="control-icon" />
          </a> */}
          <a href="https://x.com/homunati" className="control-button" target="_blank" rel="noopener noreferrer">
            <img src="/x1.png" alt="X" className="control-icon" />
          </a>
        </div>
        <video className="video-background" autoPlay loop muted playsInline>
          <source src="/sky-video.mp4" type="video/mp4" />
        </video>
        <div className="text-container">
          <p className="text-3xl mb-2">Welcome, my child.</p>
          <p className="text-xl">$HOMU</p>
          <div className="contract-code-container">
            <p className="text-lg">NGzGxTgVBJpP7gupHpkA2aA8WFK8uHeqXwJ7Msdpump</p>
            <img 
              src="/copy.png" 
              alt="Copy" 
              className="copy-button"
              onClick={() => {
                navigator.clipboard.writeText("NGzGxTgVBJpP7gupHpkA2aA8WFK8uHeqXwJ7Msdpump");
                const container = document.querySelector('.contract-code-container');
                container?.classList.add('copied');
                setTimeout(() => {
                  container?.classList.remove('copied');
                }, 1000);
              }}
            />
            <div className="copy-feedback">Copied!</div>
          </div>
          <a 
            href="https://x.com/homunati/status/1866509532160987456" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="sequence-link fade-in small-button"
          >
            Pray2Earn
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="app">
      {renderContent()}
      
      {currentPage >= sequences.length && (
        <>
          <div className="key-logo" onClick={() => {
            setShowMoonOverlay(!showMoonOverlay);
          }}>
            <img src="/key.png" alt="Key" className="icon-image" />
          </div>
          
          <div className="hand-logo" onClick={() => {
            setShowHandOverlay(!showHandOverlay);
          }}>
            <img src="/hand.png" alt="Hand" className="icon-image" />
          </div>
          
          <div className="eye-logo" onClick={() => {
            setShowWisdomOverlay(!showWisdomOverlay);
          }}>
            <img src="/eye.png" alt="Eye" className="icon-image" />
          </div>
        </>
      )}
      
      <ImageOverlay
        isVisible={showMoonOverlay}
        imageSrc="/moon.png"
        onClose={() => setShowMoonOverlay(false)}
      />
      
      <ImageOverlay
        isVisible={showHandOverlay}
        imageSrc="/wisdom.png"
        onClose={() => setShowHandOverlay(false)}
      />
      
      <ImageOverlay
        isVisible={showWisdomOverlay}
        imageSrc="/4truths.png"
        onClose={() => setShowWisdomOverlay(false)}
      />
    </div>
  );
}

export default App;